<template>
  <v-card>
    <v-card-title class="align-start">
      <span>Client Revenue</span>
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-subtitle>
    Total Clients: {{ clientCount }}
    </v-card-subtitle>

    <v-card-text>
      <!-- Chart -->
      <vue-apex-charts :options="chartOptions" :series="clientwiseAmount"></vue-apex-charts>
    </v-card-text>
    <v-divider class="my-2" v-if="alertMsg.length > 1"></v-divider>
    
    <v-alert
      v-model="showAlert"
      :type="alertType"
      dismissible
    >
      <span v-html="alertMsg"></span>
    </v-alert>
  </v-card>
</template>

<script>
import Vue from 'vue'
import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)
// eslint-disable-next-line object-curly-newline
import { mdiDotsVertical, mdiTrendingUp, mdiCurrencyUsd } from '@mdi/js'
import { getCurrentInstance, ref } from '@vue/composition-api'

import { getClientwiseRevenue } from "../../services/apiCall.js";

export default {
  components: {
    VueApexCharts,
  },
  data: () => ({
    showAlert: false,
    alertMsg: '',
    alertType:'success',
    clientCount: 0,
    currentFinYear: '',
    chartOptions: {
      chart: {
        type: 'donut',
        toolbar: {
          show: false,
        },
        offsetX: -15,
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                show: true,
                label: 'Total Revenue',
                formatter: function (w) {
                    let formattedValue = w.globals.seriesTotals.reduce((a, b) => {
                      return a + b
                    }, 0);

                    formattedValue = formattedValue + '';
                    if (formattedValue.length > 5) {
                      formattedValue = formattedValue.slice(0,-5) + ',' + formattedValue.slice(-5);
                    }
                    if (formattedValue.length > 3) {
                      formattedValue = formattedValue.slice(0,-3) + ',' + formattedValue.slice(-3);
                    } 
                    return  '₹' + formattedValue;
                }
              }
            }
          },
          customScale: 0.9,
        },
      },
      dataLabels: {
        enabled: true,
        minAngleToShowLabel: 0       
      },
      legend: {
        show: false,
      },
      tooltip: {
        y: {
          formatter: function(value) {
            let formattedValue = value + '';
            if (formattedValue.length > 5) {
              formattedValue = formattedValue.slice(0,-5) + ',' + formattedValue.slice(-5);
            }
            if (formattedValue.length > 3) {
              formattedValue = formattedValue.slice(0,-3) + ',' + formattedValue.slice(-3);
            } 
            return  '₹' + formattedValue;
          }
        }
      },
    },
    clientwiseAmount: [],
    clientNames: [],
    icons: {
        mdiDotsVertical,
        mdiTrendingUp,
        mdiCurrencyUsd,
      },

  }),

  setup() {
    return {
      getClientwiseRevenue,
    }
  },
  mounted:function() {
    this.init();
  },
  methods: {
    init() {
      this.getClientwiseRevenue()
        .then(async (res) => {
          //console.log('Revenue List:', res);
          if (res.data.status === 0) {
            let clientwiseRevenue = res.data.result.clientwise_revenue;
            let allYears = clientwiseRevenue.filter((y) => y.year === 'all')[0];
            this.clientCount = allYears['clients'].length;
            this.clientNames = allYears['clients'].map((x) => x.client_name);
            this.chartOptions = { 'labels': this.clientNames };
            this.clientwiseAmount = allYears['clients'].map((x) => x.amount);
            
          } else {
            this.showAlertMessage("error", res.data.error.error_message);
          }
        })
        .catch(async (err) => {
          this.showAlertMessage("error", "Monthwise revenue failed" + ": " + err.toString());
        });
    },

    
    showAlertMessage(alertType, msg) {
      this.showAlert = true;
      this.alertType = alertType;
      this.alertMsg = msg;
    },

    formatAmount(value) {
      let formattedValue = value + '';
      if (formattedValue.length > 5) {
        formattedValue = formattedValue.slice(0,-5) + ',' + formattedValue.slice(-5);
      }
      if (formattedValue.length > 3) {
        formattedValue = formattedValue.slice(0,-3) + ',' + formattedValue.slice(-3);
      }

      return formattedValue;
    },
    
  }
}
</script>
