<template>
  <v-row class="match-height">
    <!-- Summary Card -->
    <v-col
      cols="12"
      md="12"
      class="pa-1"
    >
      <summary-card
        :yearlyRevenue="yearlyRevenue"
        :totalClients="totalClients"
        :totalGST="totalGST"
        :totalTDS="totalTDS"
        :totalOrders="totalOrders"
        :totalMolecules="totalMolecules"
        :currentFinYear="currentFinYear"
      >
      </summary-card>
    </v-col>
    <v-col
      cols="12"
      md="12"
      class="pa-1"
    >
      <pending-task-card
        :pendingOrders="pendingOrders"
        :pendingPaymentOrders="pendingPaymentOrders"
        :pendingAmount="pendingAmount"
        :pendingTDSInvoices="pendingTDSInvoices"
        :pendingTDSAmount="pendingTDSAmount"
      >
      </pending-task-card>
    </v-col>
    <v-col
      cols="12"
      sm="6"
      md="6"
      class="pa-1"
    >
      <monthwise-revenue-chart
        :currentFinYear="currentFinYear"
        :yearlyRevenue="yearlyRevenue"
        :xSeriesData="newCategories" 
        :ySeriesData="monthwiseAmount"
      ></monthwise-revenue-chart>
    </v-col>
    <v-col
      cols="12"
      sm="6"
      md="6"
      class="pa-1"
    >
      <client-revenue-chart></client-revenue-chart>
    </v-col>
    <v-col
      cols="12"
      sm="12"
      md="12"
    >
      <v-divider class="my-2" v-if="alertMsg.length > 1"></v-divider>
      <v-alert
        v-model="showAlert"
        :type="alertType"
        dismissible
      >
        <span v-html="alertMsg"></span>
      </v-alert>
    </v-col>
  </v-row>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { ref } from '@vue/composition-api'

import SummaryCard from '../cards/SummaryCard.vue'
import PendingTaskCard from '../cards/PendingTaskCard.vue'
import MonthwiseRevenueChart from './MonthwiseRevenueChart.vue'
import ClientRevenueChart from './ClientRevenueChart.vue'


import { isUserLoggedin, getCurrentFinancilaYear } from "../../services/utils.js";
import { getYearwiseTDS, getMonthwiseRevenue, getPendingTasks } from "../../services/apiCall.js";

export default {
  components: {
    SummaryCard,
    PendingTaskCard,
    MonthwiseRevenueChart,
    ClientRevenueChart,
  },
  setup() {

    const totalClients = ref(0)
    const totalTDS = ref(0)
    const totalGST = ref(0)
    const totalOrders = ref(0)
    const totalMolecules = ref(0)
    const currentFinYear = ref('')
    const monthwiseAmount = ref([])
    const newCategories = ref([])
    const yearlyRevenue = ref(0)
    const showAlert = ref(false)
    const alertMsg = ref('')
    const alertType = ref('success')
    const pendingOrders = ref(0)
    const pendingPaymentOrders = ref(0)
    const pendingAmount = ref(0)
    const pendingTDSInvoices = ref(0)
    const pendingTDSAmount = ref(0)

    return {
      isUserLoggedin,
      getCurrentFinancilaYear,
      getYearwiseTDS,
      getMonthwiseRevenue,
      getPendingTasks,
      totalClients,
      totalTDS,
      totalGST,
      totalOrders,
      totalMolecules,
      currentFinYear,
      monthwiseAmount,
      newCategories,
      yearlyRevenue,
      showAlert,
      alertMsg,
      alertType,
      pendingOrders,
      pendingPaymentOrders,
      pendingAmount,
      pendingTDSInvoices,
      pendingTDSAmount,
    }
  },
  mounted:function(){
    this.init();
    this.getYearlyTDS();
    this.monthlyRevenue();
    this.pendingTasks();
  },
  methods: {
    init() {
      // load user credentials, if available 
      this.$store.dispatch("loaduser");
      // console.log('User not loggedin');

      if (!isUserLoggedin(this.$store)) {
        console.log('User not loggedin');
        this.$router.replace("/pages/login");
      }
    },

    showAlertMessage(alertType, msg) {
      this.showAlert = true;
      this.alertType = alertType;
      this.alertMsg = msg;
    },

    getYearlyTDS() {
      this.currentFinYear = this.getCurrentFinancilaYear();
      this.getYearwiseTDS()
        .then(async (res) => {
          //console.log('getYearwiseTDS List:', res);
          if (res.data.status === 0) {
            let yearlyTDS = res.data.result.yearwise_tds.filter((y) => y.year === this.currentFinYear);
            if (yearlyTDS.length) {
              this.totalTDS = yearlyTDS[0].tds_amount;
            }
          } else {
            this.showAlertMessage("error", res.data.error.error_message);
          }
        })
        .catch(async (err) => {
          this.showAlertMessage("error", "Monthwise revenue failed" + ": " + err.toString());
        });
    },

    monthlyRevenue() {
      this.getMonthwiseRevenue()
        .then(async (res) => {
          // console.log('Revenue List:', res);
          if (res.data.status === 0) {
            this.currentFinYear = res.data.result.current_financial_year;
            this.yearlyRevenue = res.data.result.current_year_revenue;
            this.totalGST = res.data.result.current_year_gst;
            this.totalOrders = res.data.result.current_year_orders;
            this.totalMolecules = res.data.result.current_year_molecules;
            this.totalClients = res.data.result.current_year_clients;
            let monthwiseRevenue = res.data.result.monthwise_revenue;
            let newCategories = monthwiseRevenue.map((x) => x.month.substring(0,3));
            this.newCategories = newCategories;
            let newData = monthwiseRevenue.map((x) => parseInt(x.amount));
            this.monthwiseAmount = [{ name: 'Revenue', data: newData }];
          } else {
            this.showAlertMessage("error", res.data.error.error_message);
          }
        })
        .catch(async (err) => {
          this.showAlertMessage("error", "Monthwise revenue failed" + ": " + err.toString());
        });
    },

    pendingTasks() {
      this.getPendingTasks()
        .then(async (res) => {
          // console.log('Pending task:', res);
          if (res.data.status === 0) {
            this.pendingOrders = res.data.result.orders_due;
            this.pendingPaymentOrders = res.data.result.payment_due.orders;
            this.pendingAmount = res.data.result.payment_due.due_amount;
            this.pendingTDSInvoices = res.data.result.tds_due.invoices;
            this.pendingTDSAmount = res.data.result.tds_due.due_amount;
          }
        })
        .catch(async (err) => {
          this.showAlertMessage("error", "Get pending tasks failed" + ": " + err.toString());
        });
    }
  }
}
</script>
