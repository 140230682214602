<template>
  <v-card class="pa-1">
    <v-card-title class="align-start">
      Summary

      <v-spacer></v-spacer>

      <v-btn
        icon
        small
        class="me-n3 mt-n2"
      >
        <v-icon>
          {{ icons.mdiDotsVertical }}
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-subtitle>
      Year: {{ currentFinYear }}
    </v-card-subtitle>

    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <div class="d-flex">
            <v-avatar
              size="44"
              color="primary"
              rounded
              class="elevation-1"
            >
              <v-icon
                dark
                color="white"
                size="30"
              >
                {{ icons.mdiCurrencyInr }}
              </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="text-xs mb-0">
                Revenue
              </p>
              <span class="text--primary text-xl font-weight-bold"> ₹{{ numberFormatter(yearlyRevenue) }}</span>
            </div>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <div class="d-flex">
            <v-avatar
              size="44"
              color="error"
              rounded
              class="elevation-1"
            >
              <v-icon
                dark
                color="white"
                size="30"
              >
                {{ icons.mdiGoogleDownasaur }}
              </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="text-xs mb-0">
                GST
              </p>
              <span class="text--primary text-xl font-weight-bold">₹{{ numberFormatter(totalGST) }}</span>
            </div>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <div class="d-flex">
            <v-avatar
              size="44"
              color="warning"
              rounded
              class="elevation-1"
            >
              <v-icon
                dark
                color="white"
                size="30"
              >
                {{ icons.mdiChevronTripleDown }}
              </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="text-xs mb-0">
                TDS
              </p>
              <span class="text--primary text-xl font-weight-bold">₹{{ numberFormatter(totalTDS) }}</span>
            </div>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <div class="d-flex">
            <v-avatar
              size="44"
              color="success"
              rounded
              class="elevation-1"
            >
              <v-icon
                dark
                color="white"
                size="30"
              >
                {{ icons.mdiFamilyTree }}
              </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="text-xs mb-0">
                Clients
              </p>
              <span class="text--primary  text-xl font-weight-bold"> {{ totalClients }}</span>
            </div>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <div class="d-flex">
            <v-avatar
              size="44"
              color="info"
              rounded
              class="elevation-1"
            >
              <v-icon
                dark
                color="white"
                size="30"
              >
                {{ icons.mdiPaperRollOutline }}
              </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="text-xs mb-0">
                Orders
              </p>
              <span class="text--primary text-xl font-weight-bold">{{ numberFormatter(totalOrders) }}</span>
            </div>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <div class="d-flex">
            <v-avatar
              size="44"
              color="info"
              rounded
              class="elevation-1"
            >
              <v-icon
                dark
                color="white"
                size="30"
              >
                {{ icons.mdiMolecule }}
              </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="text-xs mb-0">
                Molecules
              </p>
              <span class="text--primary text-xl font-weight-bold">{{ numberFormatter(totalMolecules) }}</span>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiFamilyTree,
  mdiCurrencyInr, 
  mdiPaperRollOutline,
  mdiDotsVertical,
  mdiGoogleDownasaur,
  mdiPiggyBankOutline,
  mdiChevronTripleDown,
  mdiMolecule,
} from '@mdi/js'

export default {
  props: {
    yearlyRevenue: { type: Number, default: 0 },
    totalClients: { type: Number, default: 0 },
    totalGST: { type: Number, default: 0 },
    totalTDS: { type: Number, default: 0 },
    totalOrders: { type: Number, default: 0 },
    totalMolecules: { type: Number, default: 0 },
    currentFinYear: { type: String, default: '' },
  },
  setup() {
    const numberFormatter = (value) => {
      let formattedValue = value + '';
      if (formattedValue.length > 5) {
        formattedValue = formattedValue.slice(0,-5) + ',' + formattedValue.slice(-5);
      }
      if (formattedValue.length > 3) {
        formattedValue = formattedValue.slice(0,-3) + ',' + formattedValue.slice(-3);
      } 
      return formattedValue;
    }

    return {
      // icons
      numberFormatter,
      icons: {
        mdiDotsVertical,
        mdiPaperRollOutline,
        mdiFamilyTree,
        mdiGoogleDownasaur,
        mdiCurrencyInr,
        mdiPiggyBankOutline,
        mdiChevronTripleDown,
        mdiMolecule,
      },
    }
  },
}
</script>
