<template>
  <v-card>
    <v-card-title class="align-start">
      <span>Monthly Revenue</span>
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-subtitle>
    Last 12 months
    </v-card-subtitle>

    <v-card-text>
      <!-- Chart -->
      <vue-apex-charts  type="bar" :options="chartOptionsComputed" :series="categoriesComputed"></vue-apex-charts>
    </v-card-text>
  </v-card>
</template>

<script>

import Vue from 'vue'
import { computed } from '@vue/composition-api'

import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

import { mdiDotsVertical, mdiTrendingUp, mdiCurrencyUsd } from '@mdi/js'
import { getCurrentInstance, ref } from '@vue/composition-api'

//import { getMonthwiseRevenue } from "../../services/apiCall.js";

export default {
  components: {
    VueApexCharts,
  },

  props: {
    currentFinYear: { type: String, default: '' },
    yearlyRevenue: { type: Number, default: 0 },
    xSeriesData: { type: Array, default: [] },
    ySeriesData: { type: Array, default: [] },
  },

  data: () => ({
    icons: {
        mdiDotsVertical,
        mdiTrendingUp,
        mdiCurrencyUsd,
      },

  }),

  setup(props) {
    const chartOptions = {
      chart: {
        type: 'bar',
        toolbar: {
          show: false,
        },
        offsetX: -15,
      },
      plotOptions: {
        bar: {
          columnWidth: '40%',
          distributed: true,
          borderRadius: 8,
          startingShape: 'rounded',
          endingShape: 'rounded',
        },
      },
      dataLabels: {
        enabled: false,
        formatter: function(value) {
          let formattedValue = value + '';
          if (formattedValue.length > 5) {
            formattedValue = formattedValue.slice(0,-5) + ',' + formattedValue.slice(-5);
          }
          if (formattedValue.length > 3) {
            formattedValue = formattedValue.slice(0,-3) + ',' + formattedValue.slice(-3);
          } 
          return  '₹' + formattedValue;
        },
      },
      tooltip: {
        y: {
          formatter: function(value) {
            let formattedValue = value + '';
            if (formattedValue.length > 5) {
              formattedValue = formattedValue.slice(0,-5) + ',' + formattedValue.slice(-5);
            }
            if (formattedValue.length > 3) {
              formattedValue = formattedValue.slice(0,-3) + ',' + formattedValue.slice(-3);
            } 
            return  '₹' + formattedValue;
          }
        }
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
        axisBorder: {
          show: false,
        },
        tickPlacement: 'on',
        labels: {
          show: true,
          style: {
            fontSize: '12px',
          },
        },
      },
      yaxis: {
        show: true,
        tickAmount: 4,
        labels: {
          offsetY: 3,
          formatter: value => `₹${value/1000}`,
        },
      },
      stroke: {
        width: [2, 2],
      },
      grid: {
        strokeDashArray: 12,
        padding: {
          right: 0,
        },
      },
    }

    const yData = [
      {
        name: 'Revenue',
        data: [],
      },
    ]

    const chartOptionsComputed = computed(() => {
      const options = JSON.parse(JSON.stringify(chartOptions))
      if (props.xSeriesData.length === 0) {        
        return options
      }
      
      options['xaxis']['categories'] = props.xSeriesData;
      return options
    })

    const categoriesComputed = computed(() => {
      if (props.ySeriesData.length === 0) {   
        const cat = JSON.parse(JSON.stringify(chartOptions))     
        return yData
      }

      return props.ySeriesData
    })

    const numberFormatter = (value) => {
      let formattedValue = value + '';
      if (formattedValue.length > 5) {
        formattedValue = formattedValue.slice(0,-5) + ',' + formattedValue.slice(-5);
      }
      if (formattedValue.length > 3) {
        formattedValue = formattedValue.slice(0,-3) + ',' + formattedValue.slice(-3);
      } 
      return formattedValue;
    }

    return {
      numberFormatter,
      chartOptionsComputed,
      categoriesComputed,
    }
  },
}
</script>
