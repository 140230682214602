<template>
  <v-card class="pa-1">
    <v-card-title class="align-start">
      Due
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <div class="d-flex">
            <v-avatar
              size="44"
              color="primary"
              rounded
              class="elevation-1"
            >
              <v-icon
                dark
                color="white"
                size="30"
              >
                {{ icons.mdiCartMinus }}
              </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="text-xs mb-0">
                Reports
              </p>
              <span class="text--primary text-xl font-weight-bold"> {{ pendingOrders }}</span>
            </div>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <div class="d-flex">
            <v-avatar
              size="44"
              color="error"
              rounded
              class="elevation-1"
            >
              <v-icon
                dark
                color="white"
                size="30"
              >
                {{ icons.mdiCashMinus }}
              </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="text-xs mb-0">
                Payment
              </p>
              <span class="text--primary text-xl font-weight-bold">₹{{ numberFormatter(pendingAmount) }}</span>
              <p class="text-xs mb-0">
                {{ pendingPaymentOrders }} Orders
              </p>
            </div>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <div class="d-flex">
            <v-avatar
              size="44"
              color="warning"
              rounded
              class="elevation-1"
            >
              <v-icon
                dark
                color="white"
                size="30"
              >
                {{ icons.mdiStarMinusOutline }}
              </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="text-xs mb-0">
                TDS
              </p>
              <span class="text--primary text-xl font-weight-bold">₹{{ numberFormatter(pendingTDSAmount) }}</span>
              <p class="text-xs mb-0">
                {{ pendingTDSInvoices }} Invoices
              </p>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiStarMinusOutline,
  mdiCashMinus,
  mdiCartMinus
} from '@mdi/js'

export default {
  props: {
    pendingOrders: { type: Number, default: 0 },
    pendingPaymentOrders: { type: Number, default: 0 },
    pendingAmount: { type: Number, default: 0 },
    pendingTDSInvoices: { type: Number, default: 0 },
    pendingTDSAmount: { type: Number, default: 0 },
  },
  setup() {
    const numberFormatter = (value) => {
      let formattedValue = value + '';
      if (formattedValue.length > 5) {
        formattedValue = formattedValue.slice(0,-5) + ',' + formattedValue.slice(-5);
      }
      if (formattedValue.length > 3) {
        formattedValue = formattedValue.slice(0,-3) + ',' + formattedValue.slice(-3);
      } 
      return formattedValue;
    }

    return {
      // icons
      numberFormatter,
      icons: {
        mdiStarMinusOutline,
        mdiCashMinus,
        mdiCartMinus
      },
    }
  },
}
</script>
